<template>
<div class="container padding-container">
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :lg="2" :sm="4" :xs="8">
                <span class="list-title">用户列表</span>
            </el-col>
        </el-row>

        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>

                <el-table-column label="用户名" min-width="120" :show-overflow-tooltip="true">
                    <div slot-scope="scope" class="fz-bold">
                        {{scope.row.name || '-'}}
                    </div>
                </el-table-column>

                <el-table-column prop="contact_name" label="上一次登录时间" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.contact_name || '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="contact_phone" label="上一次登录IP" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.contact_phone || '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <!-- <el-table-column  label="操作" min-width="155" align="center">
                    <template slot-scope="">
                        <el-button type="text" size="small">编辑</el-button>
                        <el-button type="text" size="small">删除</el-button>
                    </template>
                </el-table-column> -->
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                @current-change="pageChange" :current-page="currPage" :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
</div>
</template>
<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            total: 0,
            currPage: 1,
            pageSize: 10,

            tableData: []
        };
    },
    watch: {},
    computed: {},
    mounted() {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.User.userList(
                this.currPage,
                this.pageSize
            ).then(res => {
                this.tableData = res.list;
                this.total = res.count;
            })
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>